<template>
  <div>
    <div class="top-tip-wrapper" v-if="order">
      <div class="top-tip-wrapper-inner">
        <div class="tip-left-time">
          <span class="icon el-icon-time"></span>
          <span class="content" v-html="$t('message.checkout.timeTip', { time: leftTime })"></span>
        </div>
        <div class="order-info margin-top-12" v-if="!order.hideBillingInformation">
          <span class="icon el-icon-bell"></span>
          <span class="content">{{ $t('message.checkout.billingAddressTip', {
            billingAddress: order &&
              order.billingAddress
          }) }}
          </span>
        </div>
      </div>
    </div>
    <div class="checkout-outer-wrapper">
      <div class="checkout-wrapper" v-loading.fullscreen="fullscreenLoading">
        <div v-if="order">
          <!-- 国家 -->
          <div v-if="viewCountryBtn !== false" class="margin-top-20 top-country">
            <div class="country-selector-box">
              <el-select @visible-change="clickCountryHandler" @change="countryChange"
                class="select-small country-selector" :popper-append-to-body="true" v-model="form.country" filterable
                autocomplete="false">
                <template slot="prefix">
                  {{ (countryList.find(item => item.code === form.country) || {}).label }}
                </template>
                <el-option v-for="(c, cindex) in countryList" :key="cindex" :value="c.code"
                  :label="c.label"></el-option>
              </el-select>
            </div>
            <el-tooltip class="gateway-light-tooltip" effect="light" popper-class="gateway-light-tooltip-popper">
              <div slot="content">{{ $t('message.checkout.changeCountryTip') }}</div>
              <el-button type="text">
                <img src="../../assets/images/tooltip.png" width="16" height="17" />
              </el-button>
            </el-tooltip>
          </div>
          <div class="payment-wrapper">
            <div class="payment-content-wrapper">
              <div class="payment-content order-box margin-top-20">
                <el-form label-position="top" :model="form" :rules="rules" ref="formRef">
                  <div class="title">{{ $t('message.checkout.cardInfo') }}</div>
                  <!-- 支付信息 -->
                  <div class="padding-left-20 padding-right-20 padding-bottom-8 padding-top-20 mobile-padding">
                    <el-row :gutter="device.device === 'WAP' ? 5 : 30"
                      v-if="order && order.paymentConfigurationFields && order.paymentConfigurationFields.length">
                      <div class="inline-form-items">
                        <template v-for="(paymentField, index) in order.paymentConfigurationFields">
                          <template v-if="paymentField.name === 'cardNum'">
                            <!-- 卡号 -->
                            <el-col :key="index" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                              <el-form-item prop="cardNum" ref="cardNumRef">
                                <template slot="label">
                                  <div class="card-number-label">
                                    <span>{{ $t('message.checkout.cardNumber') }}</span>
                                  </div>
                                </template>
                                <div>
                                  <card-number :value.sync="form.cardNum" :support-cards="order && order.cardTypeInfo"
                                    @blur="cardNumBlur" @focus="sendPaymentInfoFocusEvent(paymentField.name)"
                                    @change="sendPaymentInfoInputEvent(paymentField.name)"></card-number>
                                  <div v-if="order.cardTypeInfo" style="display: flex;flex-align:center;">
                                    <template v-for="(item, idx) of supportCardTypes">
                                      <el-tooltip :key="idx" effect="dark" :content="item.cardTypeName"
                                        style="margin-right:8px;margin-top: 5px;">
                                        <img class="img" :src="item.logoPath" height="16" style="width:auto;" />
                                      </el-tooltip>
                                    </template>
                                  </div>
                                </div>
                              </el-form-item>
                            </el-col>
                          </template>
                          <template
                            v-if="!['cardNum', 'bankName', 'issueDate', 'jpLastName'].includes(paymentField.name)">
                            <el-col
                              v-if="paymentField.name !== 'cardHolderName' || (paymentField.name === 'cardHolderName' && !hideName)"
                              :key="index" :xs="['effectiveDate', 'cvv'].includes(paymentField.name) ? 12 : 24" :sm="12"
                              :md="12" :lg="12" :xl="12">
                              <!-- 有效日期 -->
                              <el-form-item v-if="paymentField.name === 'effectiveDate'"
                                :label="$t('message.checkout.expiredDate')" prop="effectiveDate" ref="effectiveDateRef">
                                <el-input maxlength="5" placeholder="MM/YY" autocomplete="cc-exp"
                                  v-model="form.effectiveDate" @input.native="effectiveDateChangeHandler"
                                  @blur="formInlineItemBlurHandler"
                                  @focus="sendPaymentInfoFocusEvent(paymentField.name)"
                                  @change="sendPaymentInfoInputEvent(paymentField.name)" />
                              </el-form-item>
                              <!-- cvv -->
                              <el-form-item v-if="paymentField.name === 'cvv'" :label="$t('message.checkout.cvv')"
                                prop="cvv" ref="cvvRef">
                                <el-input class="cvv" maxlength="4" autocomplete="cc-csc" v-model="form.cvv"
                                  @input="cvvChangeHandler" inputmode="numeric" @blur="formInlineItemBlurHandler"
                                  @focus="sendPaymentInfoFocusEvent(paymentField.name)"
                                  @change="sendPaymentInfoInputEvent(paymentField.name)">
                                  <template slot="suffix">
                                    <div class="img-cvv-box">
                                      <el-popover placement="bottom-end" trigger="hover" :visible-arrow="false"
                                        :offset="5" popper-class="cvv-popover">
                                        <img slot="reference" class="img-cvv" src="@/assets/images/icon-cvv.png" />
                                        <div>
                                          <div class="cvv-content-top">
                                            <img src="@/assets/images/icon-cvv1.png" />
                                            <img src="@/assets/images/icon-cvv2.png" />
                                          </div>
                                          <div class="cvv-content-bottom">{{ $t('message.checkout.cvvTips') }}</div>
                                        </div>
                                      </el-popover>
                                    </div>
                                  </template>
                                </el-input>
                              </el-form-item>
                              <!-- 持卡人姓名 -->
                              <el-form-item v-if="paymentField.name === 'cardHolderName'"
                                :label="$t('message.checkout.holderName')" prop="cardHolderName"
                                ref="cardHolderNameRef">
                                <el-input maxlength="50" autocomplete="cc-name" v-model="form.cardHolderName"
                                  :placeholder="$t('message.checkout.holderNameTips')"
                                  @focus="sendPaymentInfoFocusEvent(paymentField.name)"
                                  @change="sendPaymentInfoInputEvent(paymentField.name)" />
                              </el-form-item>
                              <!-- 预付卡卡号 -->
                              <el-form-item v-if="paymentField.name === 'prepaidNumber'"
                                :label="$t('message.checkout.cardNumber')" prop="prepaidNumber" ref="prepaidNumberRef">
                                <el-input maxlength="16" autocomplete="off" v-model="form.prepaidNumber"
                                  inputmode="numeric" @focus="sendPaymentInfoFocusEvent(paymentField.name)"
                                  @change="sendPaymentInfoInputEvent(paymentField.name)" />
                              </el-form-item>
                              <!-- 身份证 -->
                              <el-form-item v-if="paymentField.name === 'identityNumber'"
                                :label="identityNumberFormLabel" prop="identityNumber" ref="identityNumberRef">
                                <el-input maxlength="64" autocomplete="off" v-model="form.identityNumber"
                                  @input="onIdentityNumberInput" @focus="sendPaymentInfoFocusEvent(paymentField.name)"
                                  @change="sendPaymentInfoInputEvent(paymentField.name)" />
                              </el-form-item>
                              <!-- birth date -->
                              <el-form-item v-if="paymentField.name === 'birthDate'"
                                @input.native="birthDateChangeHandler" :label="$t('message.checkout.birthDate')"
                                prop="birthDate" ref="birthDateRef">
                                <el-input maxlength="10" autocomplete="off" v-model="form.birthDate"
                                  placeholder="YYYY/MM/DD" @focus="sendPaymentInfoFocusEvent(paymentField.name)"
                                  @change="sendPaymentInfoInputEvent(paymentField.name)" />
                              </el-form-item>
                              <!-- 银行名称,本地支付才会有 -->
                              <!-- <el-form-item
                            v-if="paymentField.name === 'bankName'"
                            :label="$t('message.checkout.bankName')"
                            prop="bankName"
                            ref="bankNameRef">
                            <el-select filterable v-model="form.bankName">
                              <el-option v-for="(bank, bankIndex) in BANK_OPTIONS[item.cardName]" :key="bankIndex" :value="bank.code" :label="bank.name"></el-option>
                            </el-select>
                          </el-form-item> -->
                              <!-- 银行账号 -->
                              <el-form-item v-if="paymentField.name === 'iBan'" :label="$t('message.checkout.iBan')"
                                prop="iBan" ref="iBanRef">
                                <el-input maxlength="64" autocomplete="off" v-model="form.iBan"
                                  @focus="sendPaymentInfoFocusEvent(paymentField.name)"
                                  @change="sendPaymentInfoInputEvent(paymentField.name)" />
                              </el-form-item>
                              <el-row v-if="paymentField.name === 'jpFirstName'"
                                :gutter="device.device === 'WAP' ? 5 : 10">
                                <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                  <el-form-item label="姓/カタカナ" prop="jpFirstName" ref="jpFirstNameRef"
                                    class="nowrap-text">
                                    <el-input maxlength="64" autocomplete="off" v-model="form.jpFirstName"
                                      placeholder="姓/カタカナ" @focus="sendPaymentInfoFocusEvent(paymentField.name)"
                                      @change="sendPaymentInfoInputEvent(paymentField.name)" />
                                  </el-form-item>
                                </el-col>
                                <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                  <el-form-item label="名/カタカナ" prop="jpLastName" ref="jpLastNameRef"
                                    class="margin-top-0">
                                    <el-input maxlength="64" autocomplete="off" v-model="form.jpLastName"
                                      placeholder="名/カタカナ" @focus="sendPaymentInfoFocusEvent('jpLastName')"
                                      @change="sendPaymentInfoInputEvent('jpLastName')" />
                                  </el-form-item>
                                </el-col>
                              </el-row>
                            </el-col>
                          </template>
                        </template>
                      </div>
                    </el-row>
                    <el-row :gutter="device.device === 'WAP' ? 5 : 30" v-if="order && order.canInstallment">
                      <!-- 分期 -->
                      <el-col :sm="24" :md="12" :lg="12" :xl="12">
                        <el-form-item prop="periodValue" :label="$t('message.checkout.intallment')">
                          <el-select v-model="form.periodValue" :disabled="instalmentDisabled"
                            popper-class="loading-select" @focus="getInstalmentList"
                            :placeholder="$t('message.checkout.intallment')" filterable autocomplete="off"
                            auto-complete="off" @visible-change="clickPeriodValueHandler"
                            @change="sendBillingInfoInputEvent('periodValue')">
                            <template v-if="!instalmentLoading && instalmentInfo && instalmentInfo.stages">
                              <template v-for="(instalmentItem, index) in instalmentInfo.stages">
                                <el-option v-if="instalmentItem.stageNumber === '1'" :key="index"
                                  :value="instalmentItem.stageNumber"
                                  :label="`${$t('message.checkout.payInFull')}`"></el-option>
                                <el-option v-else :key="index" :value="instalmentItem.stageNumber"
                                  :label="`${$t('message.checkout.payIn', { count: instalmentItem.stageNumber })} ${instalmentInfo.institution === 'DLOCAL' ? `- ${instalmentItem.stageName}` : ''}`">
                                </el-option>
                              </template>
                            </template>
                            <el-option v-if="instalmentLoading" :key="'loading'" label="加载中..." disabled value="loading"
                              class="select-loading-item">
                              <span class="iconfont el-icon-loading"></span>
                            </el-option>
                          </el-select>
                          <p v-if="!['', '1'].includes(form.periodValue)" class="intallment-tip">{{
                            $t('message.checkout.intallmentTip') }}</p>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                  <!-- 账单地址 -->
                  <div v-if="order && ((order.billConfigurationFields && order.billConfigurationFields.length))">
                    <div class="title">{{ $t('message.checkout.billingAddress') }}</div>
                    <div class="padding-left-20 padding-right-20 padding-bottom-8 padding-top-20 mobile-padding">
                      <el-row :gutter="device.device === 'WAP' ? 5 : 30">
                        <template v-for="(field, index) in order.billConfigurationFields">
                          <!-- 国家, 不可修改 -->
                          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" :key="index"
                            v-if="field.name === 'country'">
                            <el-form-item :label="$t('message.checkout.country')" prop="country" ref="countryRef">
                              <el-select filterable v-model="form.country" disabled>
                                <el-option v-for="(c, cindex) in COUNTRY_OPTIONS" :key="cindex" :value="c.code"
                                  :label="$t(`message.country.${c.code}`)"></el-option>
                              </el-select>
                            </el-form-item>
                          </el-col>
                          <!-- 州 -->
                          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" :key="index"
                            v-if="field.name === 'province'">
                            <el-form-item :label="$t('message.checkout.province')" prop="province" ref="provinceRef">
                              <!-- 国家为【US/CA/CN】时为select,其它为input -->
                              <el-select v-if="selectStateCountry.includes(form.country)" filterable
                                v-model="form.province" @visible-change="clickProvinceHandler"
                                @change="sendBillingInfoInputEvent(field.name)">
                                <el-option v-for="(s, sindex) in STATES_OPTIONS[form.country]" :key="sindex"
                                  :label="$t(`message.state.${form.country}.${s.value}`)" :value="s.value"></el-option>
                              </el-select>
                              <el-input maxlength="64" v-else autocomplete="address-level1" v-model="form.province"
                                @focus="sendBillingInfoFocusEvent(field.name)"
                                @change="sendBillingInfoInputEvent(field.name)" />
                            </el-form-item>
                          </el-col>
                          <!-- Address -->
                          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" :key="index"
                            v-if="field.name === 'address'">
                            <el-form-item :label="$t('message.checkout.address')" prop="address" ref="addressRef">
                              <el-input maxlength="256" autocomplete="street-address" v-model="form.address"
                                @focus="sendBillingInfoFocusEvent(field.name)"
                                @change="sendBillingInfoInputEvent(field.name)" />
                            </el-form-item>
                          </el-col>
                          <!-- Town/City -->
                          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" :key="index"
                            v-if="field.name === 'city'">
                            <el-form-item :label="$t('message.checkout.city')" prop="city" ref="cityRef">
                              <el-input maxlength="64" autocomplete="address-level2" v-model="form.city"
                                @focus="sendBillingInfoFocusEvent(field.name)"
                                @change="sendBillingInfoInputEvent(field.name)" />
                            </el-form-item>
                          </el-col>
                          <!-- 邮编 -->
                          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" :key="index"
                            v-if="field.name === 'postalCode'">
                            <el-form-item :label="$t('message.checkout.postalCode')" prop="postalCode"
                              ref="postalCodeRef">
                              <el-input maxlength="32" autocomplete="billing postal-code" v-model="form.postalCode"
                                @input="onPostalCodeInput" @focus="sendBillingInfoFocusEvent(field.name)"
                                @change="sendBillingInfoInputEvent(field.name)" />
                            </el-form-item>
                          </el-col>
                          <!-- 邮箱 -->
                          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" :key="index"
                            v-if="field.name === 'email'">
                            <el-form-item :label="$t('message.checkout.email')" prop="email" ref="emailRef">
                              <el-autocomplete :maxlength="256" v-model="form.email" autocomplete="email"
                                :trigger-on-focus="false" :fetch-suggestions="queryEmailSearch"
                                @focus="sendBillingInfoFocusEvent(field.name)"
                                @change="sendBillingInfoInputEvent(field.name)" />
                            </el-form-item>
                          </el-col>
                          <!-- Contact Name -->
                          <el-col v-if="field.name === 'firstName'" :key="index" :xs="24" :sm="12" :md="12" :lg="12"
                            :xl="12">
                            <el-row :gutter="device.device === 'WAP' ? 5 : 10">
                              <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                <el-form-item :label="$t('message.checkout.contactName')" prop="firstName"
                                  ref="firstNameRef" class="nowrap-text">
                                  <el-input maxlength="64" autocomplete="off" v-model="form.firstName"
                                    :placeholder="$t('message.checkout.firstName')"
                                    @focus="sendBillingInfoFocusEvent(field.name)"
                                    @change="sendBillingInfoInputEvent(field.name)" />
                                </el-form-item>
                              </el-col>
                              <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                                <el-form-item prop="lastName" ref="lastNameRef" class="margin-top-23">
                                  <el-input maxlength="64" autocomplete="off" v-model="form.lastName"
                                    :placeholder="$t('message.checkout.lastName')"
                                    @focus="sendBillingInfoFocusEvent('lastName')"
                                    @change="sendBillingInfoInputEvent('lastName')" />
                                </el-form-item>
                              </el-col>
                            </el-row>
                          </el-col>
                          <!-- 手机号码 -->
                          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" :key="index"
                            v-if="field.name === 'phone'">
                            <el-form-item :label="$t('message.checkout.phone')" prop="phone" ref="phoneRef">
                              <el-input maxlength="32" autocomplete="off" v-model="form.phone"
                                @focus="sendBillingInfoFocusEvent(field.name)"
                                @change="sendBillingInfoInputEvent(field.name)" />
                            </el-form-item>
                          </el-col>
                          <!-- Street -->
                          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" :key="index"
                            v-if="field.name === 'street'">
                            <el-form-item :label="$t('message.checkout.street')" prop="street" ref="streetRef">
                              <el-input maxlength="64" autocomplete="off" v-model="form.street"
                                @focus="sendBillingInfoFocusEvent(field.name)"
                                @change="sendBillingInfoInputEvent(field.name)" />
                            </el-form-item>
                          </el-col>
                          <!-- 门牌号 -->
                          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" :key="index"
                            v-if="field.name === 'number'">
                            <el-form-item :label="$t('message.checkout.number')" prop="number" ref="numberRef">
                              <el-input maxlength="64" autocomplete="off" v-model="form.number"
                                @focus="sendBillingInfoFocusEvent(field.name)"
                                @change="sendBillingInfoInputEvent(field.name)" />
                            </el-form-item>
                          </el-col>
                        </template>
                      </el-row>
                    </div>
                  </div>
                </el-form>
                <div class="btns padding-top-18 padding-bottom-12">
                  <div class="policy-text pc">
                    <span>{{ $t('message.checkout.policy.prefix') }}</span>
                    <el-button type="text" @click="viewPolicy">{{ $t('message.checkout.privacy') }}</el-button>
                    <span>{{ $t('message.checkout.policy.suffix') }}</span>
                  </div>
                  <el-button @click="submit" type="primary" class="btn-submit">{{ $t('message.checkout.pay') }}
                    <template v-if="['', '1'].includes(form.periodValue)">{{ order && order.txnCurrency }} {{ order &&
                      order.txnAmount }}</template>
                  </el-button>
                </div>
                <!-- <div class="btns padding-bottom-12"><el-button @click="cancel" type="text">{{$t('message.checkout.cancel')}}</el-button></div> -->
              </div>
              <p v-if="viewCancelBtn" class="btn-cancel-box margin-top-10"><span class="btn-cancel" @click="onCancel">{{
                $t('message.checkout.returnMerchant') }}</span></p>
            </div>
            <div class="product-content order-box margin-left-25 margin-top-20">
              <!-- 订单信息 -->
              <div class="order-summary-wrapper">
                <div class="title">{{ $t('message.checkout.summary') }}</div>
                <div class="padding-left-20 padding-right-20 padding-bottom-24 padding-top-16 mobile-padding">
                  <div class="summary-item" v-if="viewTxnId !== false">
                    <label>{{ $t('message.checkout.orderno') }}:</label>
                    <div class="content">{{ order && order.merchantTxnId }}</div>
                  </div>
                  <div class="summary-item" v-if="!order.hideWebsite">
                    <label>{{ $t('message.checkout.website') }}:</label>
                    <div class="content">{{ order && order.website }}</div>
                  </div>
                  <div class="summary-item">
                    <label>{{ $t('message.checkout.amount') }}:</label>
                    <div class="content amount">{{ order && order.txnCurrency }} {{ order && order.txnAmount }}</div>
                  </div>
                </div>
              </div>
              <!-- 订阅支付 -->
              <div class="subscription-wrapper"
                v-if="order && order.subProductType === 'SUBSCRIBE' && order.subscription">
                <div class="title">{{ $t('message.checkout.subscription') }}</div>
                <div class="padding-left-20 padding-right-20 padding-bottom-98 padding-top-20 mobile-padding">
                  <div class="summary-item">
                    <label>{{ $t('message.checkout.schedule') }}:</label>
                    <div class="content">{{ $t('message.checkout.frequencyData.D', {
                      time:
                        order.subscription.frequencyPoint
                    }) }}
                    </div>
                  </div>
                  <!-- <div class="summary-item">
                <label>{{$t('message.checkout.endTime')}}:</label>
                <div class="content">
                  {{$t('message.checkout.endTimeData', {
                    year: order.subscription.expireDateShow[0],
                    month: $t(`message.date.month.${order.subscription.expireDateShow[1]}`),
                    day: order.subscription.expireDateShow[2],
                  })}}
                </div>
              </div> -->
                  <div class="summary-item">
                    <label class="tip">*{{ $t('message.checkout.subscriptionTip') }}</label>
                  </div>
                </div>
              </div>
            </div>
            <p class="btn-cancel-box mobile margin-top-10"><span v-if="viewCancelBtn" class="btn-cancel"
                @click="onCancel">{{
                  $t('message.checkout.returnMerchant') }}</span></p>
            <div class="btns mobile">
              <div class="policy-text mobile">
                <span>{{ $t('message.checkout.policy.prefix') }}</span>
                <el-button type="text" @click="viewPolicy">{{ $t('message.checkout.privacy') }}</el-button>
                <span>{{ $t('message.checkout.policy.suffix') }}</span>
              </div>
              <el-button @click="submit" type="primary" class="btn-submit">{{ $t('message.checkout.pay') }}
                <template v-if="['', '1'].includes(form.periodValue)">{{ order && order.txnCurrency }} {{ order &&
                  order.txnAmount }}</template>
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Privacy :open="showPolicy" @on-close="showPolicy = false"></Privacy>
  </div>
</template>
<script>
import {
  getOrderInfo,
  getCancelUrl,
  payment,
  getFieldConfig,
  getInstallmentInfo,
} from '@/api/checkout';
import { addLog } from '@/api/common';
import CardNumber from '@/components/cardnumber/index.vue';
import Privacy from '@/components/privacy/index.vue';
import BANK_OPTIONS from '@/utils/tools/bank';
import COUNTRY_OPTIONS from '@/utils/tools/country';
import EMAIL_OPTIONS from '@/utils/tools/email';
import STATES_OPTIONS from '@/utils/tools/state';
import {
  getLeftTime,
  exceptionShow,
  selectStateCountry,
  identityNumberLabel,
  paymentFields,
  billingFields,
  paymentFieldsSort,
  billingFieldsSort,
  fieldConfigurationRulesAndNamesHandler,
  deleteFieldConfigurationRules,
  showMessage,
  getBrowerInfo,
  getDevice,
  getCardExpiredFullYear,
  unzipOrder,
} from '@/utils/tools/utils';
import { cardFormRules, getCard, allRules, luhn } from '@/utils/validator';

export default {
  components: { CardNumber, Privacy },
  data() {
    return {
      showPolicy: false,
      marginBottom: '10px',
      device: getDevice(),
      BANK_OPTIONS,
      COUNTRY_OPTIONS,
      STATES_OPTIONS,
      countryList: [],
      allRules,
      leftTime: '',
      hideName: false, // 如果holderName传了值，就隐藏卡支付里的姓名输入框
      viewTxnId: true, // 是否展示订单号
      viewCancelBtn: false, // 是否展示取消按钮
      viewCountryBtn: true, // 是否展示国家选择项
      form: {
        cardNum: '',
        effectiveDate: '',
        cvv: '',
        cardHolderName: '',
        prepaidNumber: '',
        identityNumber: '',
        birthDate: '',
        iBan: '',
        jpFirstName: '',
        jpLastName: '',
        country: '',
        province: '',
        address: '',
        city: '',
        postalCode: '',
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        street: '',
        number: '',
        intallment: 2,
        periodValue: '',
      },
      rules: cardFormRules(),
      fullscreenLoading: true,
      order: null,
      selectStateCountry,
      identityNumberLabel,
      older: {
        cardType: '',
        cardNum: '',
        country: '',
      },
      fieldConfigurationNames: [],
      freshFieldConfigSuccess: false,
      supportCardTypeNames: [],
      supportCardTypes: [],
      key: this.$route.query.key ? this.$route.query.key : this.$route.params.id,
      instalmentLoading: false,
      instalmentDisabled: true,
      instalmentRefresh: true,
      instalmentInfo: null,
    };
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    },
    identityNumberFormLabel() {
      if (this.form.country) {
        return identityNumberLabel[this.form.country] ? identityNumberLabel[this.form.country] : this.$t('message.checkout.identityNumber');
      }
      return this.$t('message.checkout.identityNumber');
    },
  },
  beforeCreate() {
    const key = this.$route.query.key ? this.$route.query.key : this.$route.params.id;
    // DM
    const { MD_ORI_ID, MD_MERCHANT_ID } = require('@/config/env.' + process.env.NODE_ENV);
    const script = document.createElement('script');
    script.src = `https://h.online-metrix.net/fp/tags.js?org_id=${MD_ORI_ID}&session_id=${MD_MERCHANT_ID}${key}`;
    document.body.appendChild(script);
    try {
      window.sessionStorage.setItem(`checkout_type_${key}`, '1');
      addLog({
        key,
        message: '进入收银台:' + window.location.href,
      });
    } catch (error) { }
  },
  created() {
    this.initMethod();
    window.addEventListener('beforeunload', this.beforeUnloadEvent);
    this.$bus.$off('lang_components').$on('lang_components', eventName => {
      this.sendGaEvent(this.order, eventName);
    });
    this.initCountryList();
  },
  beforeDestroy() {
    // gtag
    this.sendGaEvent(this.order, 'exit_checkout');
    window.removeEventListener('beforeunload', this.beforeUnloadEvent);
  },
  methods: {
    initMethod() {
      try {
        const checkoutDetail = this.$route.query.checkoutDetail;
        if (checkoutDetail) {
          this.requestingTime = -1;
          const info = unzipOrder(checkoutDetail);
          if (info) {
            this.initOrder(info);
            this.fullscreenLoading = false;
            return;
          } else {
            try {
              addLog({
                key: this.key,
                message: '解密失败:' + checkoutDetail,
              });
            } catch (err) {
              //
            }
          }
        }
      } catch (err) {
        //
      }
      this.fetchOrder();
    },
    initOrder(orderInfo) {
      if (orderInfo.paymentCountry) {
        this.form.country = orderInfo.paymentCountry;
      }
      // gtag
      this.sendGaEvent(orderInfo, 'visit_checkout');
      this.sendGaEvent(orderInfo, 'visit_checkout_time');
      if (orderInfo.status === 'F') { // F跳转，失败页面
        this.$router.push({
          path: '/result/failed',
          query: { key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: '1' },
        });
        return;
      }
      // shopify
      if (orderInfo.newCheckout) {
        window.location.href = orderInfo.newCheckout;
        return;
      }
      // 没有国家，跳转到国家选择页面
      if (!orderInfo.paymentCountry) {
        this.$router.push({
          path: '/address/s',
          query: { key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: '1' },
        });
        return;
      }
      // 卡信息回填
      if (orderInfo.cardInfo) {
        const cardNo = orderInfo.cardInfo.cardNumber;
        if (cardNo) {
          this.form.cardNum = cardNo.replace(/\s*/g, '').replace(/(.{4})/g, '$1 ').trim();
        }
        this.form.cvv = orderInfo.cardInfo.cvv;
        if (orderInfo.cardInfo.month && orderInfo.cardInfo.year) {
          const year = orderInfo.cardInfo.year;
          this.form.effectiveDate = `${orderInfo.cardInfo.month}/${(year).substring(year.length - 2, year.length)}`;
        }
        // 隐藏姓名输入框
        if (orderInfo.cardInfo.holderName) {
          const holderNameArr = orderInfo.cardInfo.holderName.trim().split(' ');
          if (holderNameArr.length > 1) { // holderName有空格，影藏holderName字段
            this.hideName = true;
          }
          this.form.cardHolderName = orderInfo.cardInfo.holderName.trim();
        }
      }
      this.$store.dispatch('app/setCards', orderInfo.cardTypeInfo);
      this.$store.dispatch('app/setBrandInfo', { ...(orderInfo.brandConfig || {}), website: orderInfo.website });
      if (orderInfo.brandConfig) {
        this.viewTxnId = orderInfo.brandConfig.viewTxnId;
        this.viewCancelBtn = orderInfo.brandConfig.viewCancelBtn;
        this.viewCountryBtn = orderInfo.brandConfig.viewCountryBtn;
      }
      this.getCardTypeInfos(orderInfo.cardTypeInfo);
      paymentFieldsSort(orderInfo.paymentConfigurationFields);
      billingFieldsSort(orderInfo.billConfigurationFields);
      const fields = [];
      if (orderInfo.paymentConfigurationFields) {
        fields.push(...orderInfo.paymentConfigurationFields);
      }
      if (orderInfo.billConfigurationFields) {
        fields.push(...orderInfo.billConfigurationFields);
      }
      this.fieldConfigurationNames = fieldConfigurationRulesAndNamesHandler(fields, this.rules, orderInfo.paymentCountry);
      this.initFormData(orderInfo);
      this.order = orderInfo;
      if (this.order.subscription) {
        if (this.order.subscription.expireDate) {
          this.order.subscription.expireDateShow = this.order.subscription.expireDate.split('-');
          this.order.subscription.expireDateShow[1] = Number(this.order.subscription.expireDateShow[1]);
        } else {
          this.order.subscription.expireDateShow = ['', ''];
        }
        // this.order.subscription.expireDateShow = this.order.subscription.expireDate.split('-');
        // this.order.subscription.expireDateShow[1] = Number(this.order.subscription.expireDateShow[1]);
      }
      this.leftTime = getLeftTime(this.order.merchantTxnTime, parseInt(this.order.effectiveMinutes) * 60 * 1000);
      const timer = setInterval(() => {
        this.leftTime = getLeftTime(this.order.merchantTxnTime, parseInt(this.order.effectiveMinutes) * 60 * 1000);
        if (this.leftTime === '00:00') {
          clearInterval(timer);
        }
      }, 1000);
    },
    fetchOrder() {
      this.requestingTime = Date.now();
      getOrderInfo({
        key: this.key,
        country: this.$route.query.c,
      }).then((res) => {
        if (res.respCode !== '20000') {
          if (exceptionShow(res.respCode, this.$router, this.$route.query.key ? this.$route.query.key : this.$route.params.id, '1')) {
            return;
          }
          this.$router.push({
            path: '/result/invalid',
            query: { key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: '1' },
          });
          return;
        }
        if (res.data.paymentCountry) {
          this.form.country = res.data.paymentCountry;
        }
        // gtag
        this.sendGaEvent(res.data, 'visit_checkout');
        this.sendGaEvent(res.data, 'visit_checkout_time');
        if (res.data.status === 'F') { // F跳转，失败页面
          this.$router.push({
            path: '/result/failed',
            query: { key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: '1' },
          });
          return;
        }
        // shopify
        if (res.data.newCheckout) {
          window.location.href = res.data.newCheckout;
          return;
        }
        // 没有国家，跳转到国家选择页面
        if (!res.data.paymentCountry) {
          this.$router.push({
            path: '/address/s',
            query: { key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: '1' },
          });
          return;
        }
        // 卡信息回填
        if (res.data.cardInfo) {
          const cardNo = res.data.cardInfo.cardNumber;
          if (cardNo) {
            this.form.cardNum = cardNo.replace(/\s*/g, '').replace(/(.{4})/g, '$1 ').trim();
          }
          this.form.cvv = res.data.cardInfo.cvv;
          if (res.data.cardInfo.month && res.data.cardInfo.year) {
            const year = res.data.cardInfo.year;
            this.form.effectiveDate = `${res.data.cardInfo.month}/${(year).substring(year.length - 2, year.length)}`;
          }
          // 隐藏姓名输入框
          if (res.data.cardInfo.holderName) {
            const holderNameArr = res.data.cardInfo.holderName.trim().split(' ');
            if (holderNameArr.length > 1) { // holderName有空格，影藏holderName字段
              this.hideName = true;
            }
            this.form.cardHolderName = res.data.cardInfo.holderName.trim();
          }
        }
        this.$store.dispatch('app/setCards', res.data.cardTypeInfo);
        this.$store.dispatch('app/setBrandInfo', { ...(res.data.brandConfig || {}), website: res.data.website });
        if (res.data.brandConfig) {
          this.viewTxnId = res.data.brandConfig.viewTxnId;
          this.viewCancelBtn = res.data.brandConfig.viewCancelBtn;
          this.viewCountryBtn = res.data.brandConfig.viewCountryBtn;
        }
        this.getCardTypeInfos(res.data.cardTypeInfo);
        paymentFieldsSort(res.data.paymentConfigurationFields);
        billingFieldsSort(res.data.billConfigurationFields);
        const fields = [];
        if (res.data.paymentConfigurationFields) {
          fields.push(...res.data.paymentConfigurationFields);
        }
        if (res.data.billConfigurationFields) {
          fields.push(...res.data.billConfigurationFields);
        }
        this.fieldConfigurationNames = fieldConfigurationRulesAndNamesHandler(fields, this.rules, res.data.paymentCountry);
        this.initFormData(res.data);
        this.order = res.data;
        if (this.order.subscription) {
          if (this.order.subscription.expireDate) {
            this.order.subscription.expireDateShow = this.order.subscription.expireDate.split('-');
            this.order.subscription.expireDateShow[1] = Number(this.order.subscription.expireDateShow[1]);
          } else {
            this.order.subscription.expireDateShow = ['', ''];
          }
        }
        this.leftTime = getLeftTime(this.order.merchantTxnTime, parseInt(this.order.effectiveMinutes) * 60 * 1000);
        const timer = setInterval(() => {
          this.leftTime = getLeftTime(this.order.merchantTxnTime, parseInt(this.order.effectiveMinutes) * 60 * 1000);
          if (this.leftTime === '00:00') {
            clearInterval(timer);
          }
        }, 1000);
        this.fullscreenLoading = false;
      }).catch(() => {
        this.fullscreenLoading = false;
        this.$router.push({
          path: '/result/invalid',
          query: { key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: '1' },
        });
      });
    },
    submit() {
      this.$refs.formRef.validate((valid) => {
        this.sendGaEvent(this.order, 'click_payment_button');
        this.sendGaEvent(this.order, 'click_result', '', { result: valid ? 'S' : 'F' });
        if (valid) {
          this.fullscreenLoading = true;
          const data = this.getPaymentData();
          payment(data).then((res) => {
            try {
              this.sendGaEvent(this.order, 'payment_result', '', { result: res.data && res.data.status ? res.data.status : 'F', result_desc: res.respMsg });
            } catch (err) { }
            if (res.respCode === '20000') {
              if (res.data.status === 'F') {
                this.$router.push({
                  path: '/result/failed',
                  query: { key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: '1' },
                });
              } if (res.data.status === 'S') {
                this.$router.push({
                  path: '/result/success',
                  query: { key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: '1' },
                });
              } else if (res.data.status === 'P') {
                this.$router.push({
                  path: '/result/pending',
                  query: { key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: '1' },
                });
              } else if (res.data.status === 'R') {
                window.location.href = res.data.redirectUrl;
              }
            } else {
              if (!exceptionShow(res.respCode, this.$router, this.$route.query.key ? this.$route.query.key : this.$route.params.id, '1')) {
                this.$router.push({
                  path: '/result/failed',
                  query: { key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: '1', error: res.respMsg ? encodeURIComponent(res.respMsg) : '' },
                });
              }
            }
            this.fullscreenLoading = false;
          }).catch(() => {
            this.$router.push({
              path: '/result/failed',
              query: { key: this.$route.query.key ? this.$route.query.key : this.$route.params.id, type: '1' },
            });
            this.fullscreenLoading = false;
          });
        } else {
          this.$nextTick(() => {
            const errorDom = document.querySelector('.is-error');
            if (errorDom) {
              errorDom.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
              });
            }
          });
        }
      });
      this.formInlineItemBlurHandler();
    },
    cancel() { // 取消支付
      this.$confirm(this.$t('message.checkout.cancelPayTip'), this.$t('message.checkout.tip'), {
        confirmButtonText: this.$t('message.checkout.yes'), // 放弃付款
        cancelButtonText: this.$t('message.checkout.no'), // 继续付款
        type: 'warning',
        cancelButtonClass: 'confirm-cancel',
        confirmButtonClass: 'confirm-submit',
        customClass: 'i-confirm-message',
      }).then(() => {
        this.fullscreenLoading = true;
        getCancelUrl(this.$route.query.key ? this.$route.query.key : this.$route.params.id).then((res) => {
          if (res.respCode !== '20000') {
            if (exceptionShow(res.respCode, this.$router, this.$route.query.key ? this.$route.query.key : this.$route.params.id, '1')) {
              return;
            }
          }
          if (res.respCode === '20000') {
            window.location.href = res.data.redirectUrl;
          }
          this.fullscreenLoading = false;
        }).catch(() => {
          this.fullscreenLoading = false;
        });
      }).catch(() => ({}));
    },
    // 返回商户
    onCancel() {
      this.sendGaEvent(this.order, 'cancel_interaction');
      let url = this.order.cancelUrl || this.order.returnUrl;
      if (url) {
        if (url.indexOf('?') > -1) {
          url = `${url}&transactionId=${this.order.txnId}`;
        } else {
          url = `${url}?transactionId=${this.order.txnId}`;
        }
        window.location.href = url;
      }
    },
    keyupName(evt, key) {
      this.form[key] = evt.target.value.replace(/[\u4e00-\u9fa5]/ig, '');
    },
    effectiveDateChangeHandler(evt) {
      if (evt.inputType === 'deleteContentBackward') {
        return;
      }
      this.form.effectiveDate = this.form.effectiveDate.replace(/[^\d/]/g, '');
      if (this.form.effectiveDate.length > 0) {
        const dateArray = this.form.effectiveDate.split('');
        if (dateArray[0] === '0' && dateArray[1] === '0') {
          dateArray[1] = 1;
          this.form.effectiveDate = dateArray.join('');
        }
        if (dateArray.length >= 2 && dateArray.filter(i => { return i === '/'; }).length < 1) {
          dateArray[2] = '/';
          this.form.effectiveDate = dateArray.join('');
        }
      }
    },
    birthDateChangeHandler(evt) {
      if (evt.inputType === 'deleteContentBackward') {
        return;
      }
      this.form.birthDate = this.form.birthDate.replace(/[^\d/]/g, '');
      if (this.form.birthDate.length > 0) {
        const dateArray = this.form.birthDate.split('');
        if (dateArray.length >= 4 && dateArray.filter(i => { return i === '/'; }).length < 1) {
          dateArray[4] = '/';
          this.form.birthDate = dateArray.join('');
        }
        if (dateArray.length >= 7 && dateArray.filter(i => { return i === '/'; }).length < 2) {
          dateArray[7] = '/';
          this.form.birthDate = dateArray.join('');
        }
      }
    },
    cvvChangeHandler() {
      this.form.cvv = this.form.cvv.replace(/[^\d]/g, '');
    },
    cardNumBlur() { // 卡号blur时触发
      const cardNum = this.form.cardNum.replace(/\s*/g, '');
      const card = getCard(this.form.cardNum, false);

      // 若输出卡种不对，则分期不可选，否则分期可选
      if (cardNum.length > 10 && luhn(cardNum)) {
        this.instalmentDisabled = false;
        if (this.older.cardNum !== cardNum) {
          this.form.periodValue = '';
          this.instalmentRefresh = true;
        }
      } else {
        this.form.periodValue = '';
        this.instalmentDisabled = true;
      }
      /**
       * 更新卡种、账单、分期信息
       * 1. 没找到前端正则匹配卡种，不同卡号，且卡号长度>10
       * 2. 前端正则可匹配到卡种，且与老卡种不同, 且卡号长度>10
       */
      const diffCardNumber = !card && this.older.cardNum !== cardNum;
      const diffCardType = card && card.cardType !== this.older.cardType;
      if ((diffCardNumber || diffCardType || !this.freshFieldConfigSuccess) && cardNum.length > 10 && luhn(cardNum)) {
        this.fieldConfigurationUpdate(cardNum);
      }
      this.older.cardNum = cardNum;
      if (card) {
        this.older.cardType = card.cardType;
      }
      if (cardNum > 0) {
        this.formInlineItemBlurHandler();
      }
    },
    onIdentityNumberInput() {
      if (this.form.country === 'BR' && this.form.identityNumber) {
        const value = this.form.identityNumber;
        let newValue = value.replace(/[^\d]/g, '');
        if (newValue.length === 11) {
          newValue = `${newValue.slice(0, 3)}.${newValue.slice(3, 6)}.${newValue.slice(6, 9)}-${newValue.slice(9, 11)}`;
        } else if (newValue.length === 14) {
          newValue = `${newValue.slice(0, 2)}.${newValue.slice(2, 5)}.${newValue.slice(5, 8)}/${newValue.slice(8, 12)}-${newValue.slice(12, 14)}`;
        }
        this.form.identityNumber = newValue;
      }
    },
    onPostalCodeInput() {
      if (this.form.country === 'US' && this.form.postalCode) {
        const value = this.form.postalCode;
        if (value.length === 9 && value.indexOf('-') === -1) {
          const newValue = `${value.slice(0, 5)}-${value.slice(5, value.length)}`;
          this.form.postalCode = newValue;
        }
      }
    },
    countryChange(value) {
      // 国家变化，state为select时，若options中没有对应的值，置空
      if (selectStateCountry.includes(value)) {
        let hasProvince = false;
        for (let i = 0; i < STATES_OPTIONS[value]; i++) {
          if (this.form.province === STATES_OPTIONS[value][i].value) {
            hasProvince = true;
            break;
          }
        }
        if (!hasProvince) {
          this.form.province = '';
        }
      }
      // 国家变化，特定国家identityNumber字段名变化, rules提示信息更新
      if (this.fieldConfigurationNames.includes('identityNumber')) {
        if ((!!this.identityNumberLabel[value]) !== (!!this.identityNumberLabel[this.older.country])) { // identityNumber Label有变动
          if (this.rules.identityNumber && this.rules.identityNumber.length) {
            if (this.identityNumberLabel[value]) {
              this.rules.identityNumber[0] = allRules.required(this.identityNumberLabel[value], false, false);
            } else {
              this.rules.identityNumber[0] = allRules.required('message.checkout.identityNumber');
            }
          }
          const fieldRef = this.$refs.identityNumberRef[0];
          if (fieldRef.$el.className.indexOf('is-error') > -1) {
            this.$refs.formRef.validateField('identityNumber');
          }
        }
      }
      // 国家变化，根据帐单国家或卡种+帐单国家动态获取字段配置中的帐单地址，不论是否分期支付
      if (luhn(this.older.cardNum)) {
        this.fieldConfigurationUpdate(this.older.cardNum);
      } else {
        this.fieldConfigurationUpdate('');
      }
      this.older.country = value;
      this.sendGaEvent(this.order, 'select_country');
    },
    intallmentChange(value) {
      // 当选择分期，分期没选项时提示错误消息
      if (value === 1 && (!this.order.stagesInformation || !this.order.stagesInformation.length)) {
        this.form.intallment = 2;
        this.form.periodValue = '';
        showMessage(this.$t('message.validator.intallmentCardOrCountryNotSupport'));
      }
    },
    getInstalmentList() {
      if (!this.instalmentRefresh) {
        return;
      }
      this.instalmentLoading = true;
      const params = {
        appId: this.order.appId,
        merchantNo: this.order.merchantNo,
        amount: this.order.txnAmount,
        currency: this.order.txnCurrency,
        cardInfo: {
          cardNumber: this.form.cardNum.replace(/\s*/g, ''),
        },
        country: this.form.country,
      };
      getInstallmentInfo(params).then((res) => {
        if (res.respCode === '20000') {
          this.instalmentRefresh = false;
          this.instalmentInfo = res.data;
          if (this.instalmentInfo && this.instalmentInfo.stages && this.instalmentInfo.stages.length === 1 && this.instalmentInfo.stages[0].stageNumber === '1') {
            this.$nextTick(() => {
              setTimeout(() => {
                this.form.periodValue = '1';
              }, 0);
            });
          }
        }
        this.instalmentLoading = false;
      }).catch(() => {
        this.instalmentLoading = false;
        showMessage(this.$t('message.error.timeout'));
      });
    },
    getPaymentData() {
      const browerInfo = getBrowerInfo();
      const submitData = {
        key: this.$route.query.key ? this.$route.query.key : this.$route.params.id,
        cardNumber: this.form.cardNum.replace(/\s*/g, ''),
        month: this.form.effectiveDate.split('/')[0],
        year: getCardExpiredFullYear(this.form.effectiveDate.split('/')[1]),
        holderName: this.form.cardHolderName.trim(),
        sessionId: this.key,
        referer: window.document.referrer,
        ...browerInfo,
      };
      if (this.form.periodValue) {
        submitData.periodValue = this.form.periodValue;
        if (this.instalmentInfo.institution === 'DLOCAL') {
          for (let i = 0; i < this.instalmentInfo.stages.length; i++) {
            const stage = this.instalmentInfo.stages[i];
            if (stage.stageNumber === this.form.periodValue) {
              submitData.installmentPlanId = stage.installmentPlanId;
              break;
            }
          }
        }
      }
      // 支付信息字段赋值
      for (let i = 0; i < paymentFields.length; i++) {
        if (['cardNum', 'effectiveDate', 'cardHolderName'].includes(paymentFields[i])) {
          continue;
        }
        submitData[paymentFields[i]] = this.fieldConfigurationNames.includes(paymentFields[i]) ? this.form[paymentFields[i]] : '';
      }
      // 账单地址字段赋值
      for (let i = 0; i < billingFields.length; i++) {
        submitData[billingFields[i]] = this.fieldConfigurationNames.includes(billingFields[i]) ? this.form[billingFields[i]] : '';
      }
      submitData.country = this.form.country;
      if (submitData.identityNumber) {
        submitData.identityNumber = submitData.identityNumber.trim();
        if (submitData.country === 'BR') {
          submitData.identityNumber = submitData.identityNumber.replace(/[^\d]/g, '');
        }
      }
      return submitData;
    },
    initFormData(data) { // 配置字段数据初始化
      if (data.paymentCountry) {
        this.form.country = data.paymentCountry;
      }
      // 订单指定分期时，默认选中分期，不显示是否分期选项
      if (data.subProductType === 'INSTALLMENT') {
        this.form.intallment = 1;
      }
      // 支付信息初始化
      if (data.paymentConfigurationFields && data.paymentConfigurationFields.length) {
        data.paymentConfigurationFields.forEach((item) => {
          if (paymentFields.includes(item.name) && !['cardNum', 'effectiveDate', 'cvv', 'cardHolderName'].includes(item.name)) {
            this.form[item.name] = item.value;
            if (item.name === 'identityNumber') { // 格式化 identityNumber
              this.onIdentityNumberInput();
            }
            if (item.name === 'postalCode') { // 格式化 identityNumber
              this.onPostalCodeInput();
            }
          }
        });
      }
      // 账单信息初始化
      if (data.billConfigurationFields && data.billConfigurationFields.length) {
        data.billConfigurationFields.forEach((item) => {
          if (billingFields.includes(item.name)) {
            this.form[item.name] = item.value;
          }
        });
        if (selectStateCountry.includes(this.form.country) && this.form.province) {
          this.form.province = this.form.province.toUpperCase().trim();
          let hasProvince = false;
          for (let i = 0; i < STATES_OPTIONS[this.form.country].length; i++) {
            if (this.form.province === STATES_OPTIONS[this.form.country][i].value) {
              hasProvince = true;
              break;
            }
          }
          if (!hasProvince) {
            this.form.province = '';
          }
        }
      }
    },
    fieldConfigurationUpdate(cardNumber) {
      try {
        addLog({
          key: this.key,
          message: '卡号变更获取字段信息',
        });
      } catch (err) {
        //
      }
      this.fullscreenLoading = true;
      // 置空分期信息
      this.form.periodValue = '';
      this.instalmentInfo = null;
      this.instalmentRefresh = true;
      getFieldConfig({
        key: this.$route.query.key ? this.$route.query.key : this.$route.params.id,
        cardNumber,
        country: this.form.country,
      }).then((res) => {
        if (res.respCode !== '20000') {
          if (exceptionShow(res.respCode, this.$router, this.$route.query.key ? this.$route.query.key : this.$route.params.id, '1')) {
            return;
          }
          this.freshFieldConfigSuccess = false;
          this.fullscreenLoading = false;
          if (res.respCode === '00020|50036') {
            showMessage(this.$t('message.error.cardNumber'));
          } else {
            showMessage(this.$t('message.error.timeout'));
          }
          return;
        }
        this.freshFieldConfigSuccess = true;
        // 更新fieldConfiguration信息
        deleteFieldConfigurationRules(this.rules);
        paymentFieldsSort(res.data.paymentConfigurationFields);
        billingFieldsSort(res.data.billConfigurationFields);
        const fields = [];
        if (res.data.paymentConfigurationFields) {
          fields.push(...res.data.paymentConfigurationFields);
          res.data.paymentConfigurationFields.forEach((item) => {
            if (paymentFields.includes(item.name) && !['cardNum', 'effectiveDate', 'cvv', 'cardHolderName'].includes(item.name)) {
              if (!this.form[item.name] && item.value) {
                this.form[item.name] = item.value;
                if (item.name === 'identityNumber') { // 格式化 identityNumber
                  this.onIdentityNumberInput();
                }
                if (item.name === 'postalCode') { // 格式化 identityNumber
                  this.onPostalCodeInput();
                }
              }
            }
          });
        }
        if (res.data.billConfigurationFields) {
          fields.push(...res.data.billConfigurationFields);
          res.data.billConfigurationFields.forEach((item) => {
            if (!this.form[item.name]) {
              this.form[item.name] = item.value;
            }
          });
          if (selectStateCountry.includes(this.form.country) && this.form.province) {
            this.form.province = this.form.province.toUpperCase().trim();
            let hasProvince = false;
            for (let i = 0; i < STATES_OPTIONS[this.form.country].length; i++) {
              if (this.form.province === STATES_OPTIONS[this.form.country][i].value) {
                hasProvince = true;
                break;
              }
            }
            if (!hasProvince) {
              this.form.province = '';
            }
          }
        }
        this.fieldConfigurationNames = fieldConfigurationRulesAndNamesHandler(fields, this.rules, this.form.country);
        this.rules = { ...this.rules };
        // 更新页面的验证提示消息
        this.$nextTick(() => {
          const timer = setTimeout(() => {
            this.$refs.formRef.validate();
            clearTimeout(timer);
          });
        });
        // this.order.fieldConfiguration = res.data.fieldConfiguration;
        this.order.paymentConfigurationFields = res.data.paymentConfigurationFields;
        this.order.billConfigurationFields = res.data.billConfigurationFields;
        // 更新cardTypeInfo
        this.$store.dispatch('app/setCards', res.data.cardTypeInfo);
        this.getCardTypeInfos(res.data.cardTypeInfo);
        this.order.cardTypeInfo = res.data.cardTypeInfo;
        this.fullscreenLoading = false;
        // 更新分期信息
        this.order.canInstallment = res.data.canInstallment;
      }).catch(() => {
        this.fullscreenLoading = false;
        this.freshFieldConfigSuccess = false;
        showMessage(this.$t('message.error.timeout'));
      });
    },
    updateValidateTip() {
      [...paymentFields, ...billingFields].forEach((field) => {
        const element = this.$refs[`${field}Ref`] && this.$refs[`${field}Ref`][0];
        if (element) {
          if (element.$el.className.indexOf('is-error') > -1) {
            this.$refs.formRef.clearValidate(field);
          }
        }
      });
    },
    async formInlineItemBlurHandler() {
      let message = '';
      await this.$refs.formRef.validateField(['effectiveDate', 'cvv'], (error) => {
        message += error;
      });
      if (message) {
        this.marginBottom = '30px';
      } else {
        this.marginBottom = '10px';
      }
    },
    getCardTypeInfos(cards) {
      this.supportCardTypeNames = [];
      this.supportCardTypes = [];
      cards.forEach((item) => {
        // [VISA, MASTERCARD]卡种去重
        if (['VISA COMMERCIAL', 'MASTERCARD COMMERCIAL'].indexOf(item.cardType) < 0 ||
          (item.cardType === 'VISA COMMERCIAL' && this.supportCardTypeNames.indexOf('VISA') < 0) ||
          (item.cardType === 'MASTERCARD COMMERCIAL' && this.supportCardTypeNames.indexOf('MASTERCARD') < 0)) {
          this.supportCardTypeNames.push(item.cardType);
          this.supportCardTypes.push(item);
        }
      });
    },
    viewPolicy() {
      this.showPolicy = true;
      // gtag
      this.sendGaEvent(this.order, 'click_privacy_policy');
    },
    clickProvinceHandler(evt) {
      if (evt) {
        this.sendGaEvent(this.order, 'focus_billing_info', 'province');
      }
    },

    clickCountryHandler(evt) {
      if (evt) {
        this.sendGaEvent(this.order, 'click_country_dropdown');
      }
    },
    clickPeriodValueHandler(evt) {
      if (evt) {
        this.sendGaEvent(this.order, 'focus_billing_info', 'periodValue');
      }
    },
    sendPaymentInfoFocusEvent(field) {
      this.sendGaEvent(this.order, 'focus_payment_info', field);
      if (field === 'cardNum' && !this.cardNumFocusTime) {
        this.cardNumFocusTime = Date.now();
      }
    },
    sendPaymentInfoInputEvent(field) {
      this.sendGaEvent(this.order, 'input_payment_info', field);
    },
    sendBillingInfoFocusEvent(field) {
      this.sendGaEvent(this.order, 'focus_billing_info', field);
    },
    sendBillingInfoInputEvent(field) {
      this.sendGaEvent(this.order, 'input_billing_info', field);
    },
    sendGaEvent(orderInfo, eventName, field = '', extra = {}) {
      try {
        const params = {
          key: this.key,
          merchant_id: orderInfo && orderInfo.merchantNo,
          // pathname: window.location.pathname,
          txn_id: orderInfo && orderInfo.txnId,
          // trigger_report_time: (new Date()).getTime(),
          checkout_type: '卡支付',
          // ...this.$store.getters['app/gaDeviceinfo'],
          ...extra,
        };
        if (field) {
          params.element_name = field;
        }
        if (['visit_checkout', 'exit_checkout', 'click_payment_button'].includes(eventName)) {
          params.checkout_order_amount = orderInfo && orderInfo.txnAmount;
          params.checkout_order_currency = orderInfo && orderInfo.txnCurrency;
          params.checkout_language = this.lang;
          params.checkout_country = this.form.country;
        }
        if (eventName === 'visit_checkout' && this.requestingTime) {
          params.api_requesting_time = (this.requestingTime === -1 ? 0 : (Date.now() - this.requestingTime));
          params.visit_checkout_count = 1;
        }
        if (eventName === 'visit_checkout_time' && this.requestingTime) {
          params.time_spent = (this.requestingTime === -1 ? 0 : (Date.now() - this.requestingTime));
        }
        if (eventName === 'click_payment_button' && this.cardNumFocusTime) {
          params.time_spent = Date.now() - this.cardNumFocusTime;
        }
        window.gtag('event', eventName, params);
      } catch (error) { }
    },
    beforeUnloadEvent() {
      this.sendGaEvent(this.order, 'exit_checkout');
    },
    queryEmailSearch(queryString, cb) {
      let results = [];
      try {
        const i = queryString.indexOf('@');
        if (i > -1 && i < queryString.length - 1) {
          results = EMAIL_OPTIONS.filter(item => item.value.includes(queryString.substring(i))).map(item => ({ value: queryString.split('@')[0] + item.value }));
        }
      } catch (err) {
        //
      }
      cb(results);
    },
    initCountryList() {
      this.countryList = COUNTRY_OPTIONS.map(item => ({
        ...item,
        label: this.$t(`message.country.${item.code}`),
      })).sort((s1, s2) => {
        try {
          const l = (this.lang === 'zh-tw' || this.lang === 'zh-cn') ? 'zh' : this.lang;
          return s1.label.localeCompare(s2.label, l, { ignorePunctuation: true });
        } catch (err) {
          //
        }
        return 1;
      });
    },
  },
  watch: {
    lang: function () {
      const rules = cardFormRules();
      const fields = [];
      if (this.order.paymentConfigurationFields) {
        fields.push(...this.order.paymentConfigurationFields);
      }
      if (this.order.billConfigurationFields) {
        fields.push(...this.order.billConfigurationFields);
      }
      fieldConfigurationRulesAndNamesHandler(fields, rules, this.form.country);
      Object.assign(this.rules, rules);
      const cardFields = ['periodValue'];
      cardFields.forEach((item) => {
        const ref = this.$refs[`${item}Ref`];
        if (ref && ref.$el.className.indexOf('is-error') > -1) {
          this.$refs.formRef.validateField(item);
        }
      });
      this.order && this.order.paymentConfigurationFields && this.order.paymentConfigurationFields.forEach((item) => {
        const ref = this.$refs[`${item.name}Ref`] && this.$refs[`${item.name}Ref`][0];
        if (ref && ref.$el.className.indexOf('is-error') > -1) {
          this.$refs.formRef.validateField(item.name);
        }
      });
      this.order && this.order.billConfigurationFields && this.order.billConfigurationFields.forEach((item) => {
        const ref = this.$refs[`${item.name}Ref`] && this.$refs[`${item.name}Ref`][0];
        if (ref && ref.$el.className.indexOf('is-error') > -1) {
          this.$refs.formRef.validateField(item.name);
        }
      });
      this.initCountryList();
    },
  },
};
</script>
<style lang="scss">
@import '@/styles/checkout.scss';
</style>
<style scoped lang="scss">
.order-box {
  .card-number-label {
    width: 100%;
    display: inline;

    &>span {
      white-space: nowrap;
      margin-right: 30px;
    }

    .payments-support {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .img {
        margin-right: 5px;

        &:last-child {
          margin-right: 0;
        }
      }

      .card-number {
        color: #99A3AD;
        font-size: 13px;
        line-height: 20px;
      }
    }
  }

  .btns {
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;

    .btn-submit {
      width: 100%;
    }
  }
}

.btns.mobile {
  display: none;
}

.btn-cancel-box {
  text-align: center;

  &.mobile {
    display: none;
  }
}

.country-selector {
  ::v-deep &.el-select {
    min-width: 315px;

    .el-input__prefix {
      display: inline-block;
      position: relative;
      box-sizing: border-box;
      padding-left: 15px;
      padding-right: 90px;
      height: 40px;
      line-height: 40px;
      color: #27272B;
      left: 0;
      visibility: hidden;
      min-width: 315px;
    }

    .el-input.el-input--prefix.el-input--suffix {
      height: 40px;
    }

    .el-input--prefix {
      height: 40px;

      .el-input__inner {
        position: absolute;
        padding-left: 15px;
      }
    }
  }
}

.cvv {
  ::v-deep &.el-input--suffix {
    .el-input__inner {
      padding-right: 60px;
    }
  }
}

@media screen and (max-width: 1140px) {
  .order-box {
    .btns {
      padding-left: 10px;
      padding-right: 10px;
      display: none;
    }
  }

  .mobile-padding {
    padding-left: 10px;
    padding-right: 10px;
  }

  .product-content {
    // margin-bottom: 100px;
  }

  .btn-cancel-box {
    display: none;

    &.mobile {
      display: block;

      // margin-bottom: 30px;
      .btn-cancel {
        display: inline;
      }
    }
  }

  .btns.mobile {
    display: block;
    position: fixed;
    left: 0;
    bottom: 22px;
    width: 100%;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;

    button {
      width: 100%;
    }
  }

  .country-selector {
    ::v-deep &.el-select {
      width: 315px;

      .el-input__prefix {
        display: none;
        min-width: auto;
      }

      .el-input__inner {
        position: relative;
        padding-left: 15px;
      }
    }

    input {
      font-weight: bold;
    }
  }
}
</style>
